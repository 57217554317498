@media screen and (min-width: 550px) {
.length{
    color: green;
    font-family: cursive;
    margin-top: 0;
    padding-top: 25px;
    font-size: 2rem;
}

.disp{
    letter-spacing: 6px;
    margin-bottom: 40px;
    margin-top: 20px;
    color: white;
    font-size: 6rem;
    font-weight: 100;
    align-self: center;
    padding-left: 100px;
    padding-right: 100px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

img{
    padding-top: 20px;
    
    width: 200px;
}

.parent{
    display: flex;
    width: 100%;
    height: 100vh;
}
table{
    padding-top: 20px;
}
#letter{
    color: white;
    background-color: black;
    padding-right: 10px;
    padding-top: 10px;
    font-size: xx-large;
    width: 50px;
}
#space{
    width: 200px;
}

.left{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 500px;
}

.right{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.status{
    margin: 0;
    font-size: 5rem;
    
}
#hn_tb_mob{
    display: none;
}

#hn_reset_web{
    color: white;
    background-color: black;
    font-size: medium;
    font-family: monospace;

}
    
#hn_reset_mob{
    display: none;

}
}




@media screen and (max-width: 549px) {

    .parent{
        display: flex;
        flex-direction: column;
    }

    .length{
        color: green;
        font-family: cursive;
        margin-top: 0;
        padding-top: 10px;
        font-size: 1.5rem;
        margin: 0;
    }
    
    .disp{
        letter-spacing: 6px;
        color: white;
        font-size: 2rem;
        font-weight: 100;
        align-self: center;
        padding-left: 100px;
        padding-right: 100px;
        margin-top: 0;
        margin-bottom: 15px;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    }
    #hn_tb_web{
        display: none;
    }

    #hn_tb_mob{
        align-self: center;
    }


    #hn_reset_web{
        display: none;
    }
    .right{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    
    #hn_reset_mob{
        color: white;
        background-color: black;
        font-size: medium;
        font-family: monospace;
        margin-bottom: 20px;
    }
    #letter{
        color: white;
        background-color: black;
        padding-right: 10px;
        padding-top: 10px;
        font-size: large;
        
    }
    .status{
        margin: 0;
    }
    .left{
        display: flex;
        justify-content: center;
    }
}