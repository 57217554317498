.rw{
    display: flex;
  }
  
  .cell_box{
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
  }
  
  
  
  .score{
    display: inline;
    position: relative;
    color: white;
    font-size: xx-large;
    font-family:monospace;
    margin-right: 20px;
    align-self: center;
  }
  
  .score_parent{
    display: flex;
    position: relative;
    height: 60px;
    width: fit-content;
  }
  
  .title_ms{
    color: white;
    font-size: 4rem;
    font-family: monospace;
    margin-left: 30px;
    margin-top: 30px;
  }
  
  .reset{
    height: 30px;
    align-self: center;
    font-size: larger;
    color: white;
    background-color: black;
    border: 0;
    margin-left: 15px;
  }
  
  .reset:hover{
    opacity: 0.5;
  }
  
  .emoji{
    align-self: center;
    font-size: 25px;
  }

  .shortcut{
    color: white;
    margin-left: 30px;
    margin-top: 30px;
    font-family: cursive;
  }

  @media screen and (max-width:1150px) {
    .title_ms{
      font-size: 3rem;
    }

    .cell_box{
      padding-top: 40px;
    }
  }