.nomargin{
    margin: 0;
}

.home{

    max-height: max-content;
}

.title{
    color: white;
    padding: 30px 0 0 30px;
    font-family: cursive;
    font-weight: 200;
}

.games{
    padding-top: 50px;
}

.games>div{
    display: flex;
    flex-direction: column;
}

.games>div>div{
    height: 200px;
    display: flex;
    justify-content: center;
}


.games>div>div>div>a>img{
    height: 180px;
    width: 362px;
    border: 2px white solid;
    margin: 5px;
    opacity: 0.7;
}

.games>div>div>div>a>img:hover{
    opacity: 1;
}

@media screen and (max-width:1150px) {

    .title{
        color: white;
        padding: 30px 0 0 30px;
        font-family: cursive;
        font-weight: 200;
    }
    
    .games>div>div{
        height: 200px;
        display: flex;
        flex-direction: column;
    }

    .games{
        padding-top: 230px;
        display: grid;
    }
    .games>div{
        justify-self: center;
    }

}